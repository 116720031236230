import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader';

function SvgBlock(path, scene, size=60, pos = new THREE.Vector3(0,0,0)) {

  this.group = new THREE.Group();

  this.size = size;
  this.height = 4;
  this.curveSegments = 4;
  this.bevelThickness = 1;
  this.bevelSize = 1;
  this.bevelEnabled = true;

  this.materials = [
    new THREE.MeshPhongMaterial( { color: 0xC32400, flatShading: false } ), // front
    new THREE.MeshPhongMaterial( { color: 0xC32400 } ) // side
  ];

  var parent = this;
  this.init = function(){
    const loader = new SVGLoader();
    loader.load( path, function ( data ) {
      //parent.group.position.y = 900;
      parent.group.position.copy(pos);
      parent.createMesh(data);
      scene.add(parent.group);

      parent.group.rotation.z = Math.PI * 2;

    });
  }

  this.createMesh = function(data) {
    const paths = data.paths;
    const group = new THREE.Group();

    for ( let i = 0; i < paths.length; i ++ ) {
      const path = paths[ i ];
      const material = new THREE.MeshBasicMaterial( {
        color: path.color,
        side: THREE.BackSide,
        depthWrite: true
      } );

      const shapes = path.toShapes( false );

      for ( let j = 0; j < shapes.length; j ++ ) {
        const shape = shapes[ j ];
        //const geometry = new THREE.ShapeGeometry( shape );

        const extrudeSettings = {
          depth: this.height,
          bevelOffset: 0,
          bevelEnabled: this.bevelEnabled,
          bevelThickness: this.bevelThickness,
          bevelSize: this.bevelSize,
          curveSegments: this.curveSegments,
          bevelSegments: this.curveSegments,
        };

        const geometry = new THREE.ExtrudeGeometry( shape, extrudeSettings );
        //const geometry = new THREE.ShapeGeometry( shape );

        geometry.computeBoundingBox();

        const mesh = new THREE.Mesh( geometry, this.materials );
        mesh.position.x -= 110;
        group.add(mesh);
      }
    }

    group.scale.x = 3;
    group.scale.y = -3;
    group.scale.z = 3;
    this.group.add( group );
  }

  this.init();
}
export { SvgBlock };
