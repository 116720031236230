import TWEEN from '@tweenjs/tween.js';
window.TWEEN = TWEEN;

import { LaunchTimer } from './modules/launchtimer';
var launcher;
var launcherClock;

import { SvgBlock } from './modules/svgblock';
var dateTitle;

var openerBreathe = false;

init();

function init() {
  const container = document.querySelector('#container');

  // if (launcher) {
  //   let homeCenteredOffset = { x: 0, y: -40, z: -800 };

  //   let launcherPos = { x: 0, y: 680, z: 0 };
  //   launcher = new LaunchTimer(null, 40, launcherPos);

  //   if (!launcher.countdown(launcher.until)) launcher = null;

  //   if (launcher) {
  //     let dateTitlePos = { x: 0, y: 880, z: 0 };
  //     dateTitle = new SvgBlock('./img/date.svg', null, 180, dateTitlePos);

  //     launcherClock = 0;

  //     let collectionTitlePos = { x: 0, y: 610, z: 0 };
  //     collectionTitle = new TextBlock('SS23 COLLECTION LAUNCH', null, 40, collectionTitlePos);

  //     launcher.group.scale = { x: 0, y: 0, z: 0 };
  //     dateTitle.group.scale = { x: 0, y: 0, z: 0 };
  //     collectionTitle.group.scale = { x: 0, y: 0, z: 0 };
  //   }
  // }

  // stats = new Stats();
  // container.appendChild(stats.dom);

  window.addEventListener('resize', onWindowResize, false);

  setTimeout(() => {
    $('#loader').addClass('out');
    $('body').addClass('rdy');
    showCollectionVideo(1400, 1000);
  }, 1500);
}

function onWindowResize() {
  // Handle resize logic here if needed
}

function showCollectionVideo(speed = 5000, delay = 0, fromCenter = false) {
  // if (launcher) {
  //   const openerScale = new TWEEN.Tween({ x: 0, y: 0, z: 0 })
  //     .to({ x: 1, y: 1, z: 1 }, speed * 0.94)
  //     .easing(TWEEN.Easing.Cubic.Out)
  //     .onUpdate((sc) => {
  //       dateTitle.group.scale = sc;
  //       collectionTitle.group.scale = sc;
  //       launcher.group.scale = sc;
  //     })
  //     .onComplete(() => {
  //       openerBreathe = true;
  //     });

  //   openerScale.delay(speed * 0.04 + delay).start();
  // } else {
    $('#home').addClass('act');
  // }
}

$(function () {
  $('#collection p, .bio .top p > *, .bio .btm a, .bio .btm span').each(function () {
    if($(this).parents('.footer').length > 0) return;
    const t = $(this).html();
    const arry = t.split(' ');
    const output = arry.map((word) => `<span class='w'>${word}&nbsp;</span>`).join('');
    $(this).html(output);
  });

  $('.pdf a').each(function () {
    const t = $(this).text();
    const arry = t.split('');
    const output = arry
      .map((char) => (char === ' ' ? '&nbsp;' : `<span class='w'>${char}</span>`))
      .join('');
    $(this).html(output);
  });

  $('.w').each(function () {
    const rX = 90 + Math.random() * 30 - 15;
    const rZ = Math.random() * 50 - 25;

    $(this).css({
      opacity: 0,
      transform: `rotateX(${rX}deg) rotateZ(${rZ}deg)`,
    });
  });

  $('a.home').click(function (e) {
    e.preventDefault();
    if (!$('#home').hasClass('act')) {
      $('div.act').removeClass('act');
      $('#home').addClass('act');
      $(this).addClass('act');
      showCollectionVideo(1400, 200);
    }
  });

  // $('#burger').on('click', function (e) {
  //   e.preventDefault();
  //   $('html').toggleClass('nav-open');
  // });
});
