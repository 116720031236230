/*
var createGeometry = require('three-bmfont-text');
var loadFont = require('load-bmfont');
*/

/*
<script src="TextGeometry.js"></script>
<script src="FontUtils.js"></script>
<script src="helvetiker_regular.typeface.js"></script>
<script src="../TextWrapper.js"></script>
*/

import { DateTime, Interval } from 'luxon';

function LaunchTimer(scene, size, pos=new THREE.Vector3(0,0,0)) {
  this.active = true;

  this.group = new THREE.Group();
  this.font;
  this.countdownTimes;
  this.countdownTexts;

  this.posOffsets = {
    d : new THREE.Vector3(-1000,0,0),
    h : new THREE.Vector3(-400,0,0),
    m : new THREE.Vector3(200,0,0),
    s : new THREE.Vector3(960,0,0),
  }

  this.until = DateTime.fromObject({
    day: 14,
    month: 6,
    year: 2022,
    hour: 15,
    minute: 0,
    zone: 'Europe/Stockholm',
  });

  this.left;

  /*
  OIKEA:
    this.until = DateTime.fromObject({
      day: 1,
      month: 3,
      year: 2021,
      hour: 16,
      minute: 0,
      zone: 'Europe/Helsinki',
    });
    */

  this.timesGeo;

  this.textMeshCountdown;
  this.textMeshCountdownTexts;

  this.size = size;
  this.height = 8;
  this.curveSegments = 4;
  this.bevelThickness = 2;
  this.bevelSize = 1.5;
  this.bevelEnabled = true;

  this.materials = [
    new THREE.MeshPhongMaterial( { color: 0xC32400, flatShading: false } ), // front
    new THREE.MeshPhongMaterial( { color: 0xC32400 } ) // side
  ];

  var parent = this;
  this.init = function(){
    //Set up Text Wrapper!!

    if(!$.isEmptyObject(this.left)){
      const loader = new THREE.FontLoader();
      loader.load( './dist/font/Majesty_Regular_fix.json', function ( response ) {
        //console.log(response);
        parent.font = response;
        parent.group.position.copy(pos);
        scene.add(parent.group);
        parent.refreshText();
      });
    } else {
      console.log('would have inited launcher but time is past');
    }
  }

  this.refreshText = function(){
    for(var i=0;i<this.group.children.length;i++){
      this.group.remove(this.group.children[i]);
    }
    //this.group.remove( this.textMeshCountdown );
    //this.group.remove( this.textMeshCountdownTexts );

    let countdown = this.countdown(this.until);

    if(!$.isEmptyObject(countdown)){

      let timeString = '';
      let days = countdown.days+'';
      let hours = countdown.hours+'';
      let mins = countdown.minutes+'';
      let secs = countdown.seconds;

      /*
      days = Math.floor(Math.random()*9.99);
      hours = Math.floor(Math.random()*24);
      mins = Math.floor(Math.random()*60);
      secs = Math.floor(Math.random()*60);
      */

      if(Math.floor(secs) > 0){
        secs = secs >= 10 ? ''+Math.floor(secs) : '0'+Math.floor(secs);
      } else {
        secs = '0';
      }

      // if ( ! this.countdownTimes ) return;

      if(days > 0) timeString += days+'D ';
      timeString += hours+'H ';
      timeString += mins+'M';
      if(days == 0) timeString += ' '+secs+'S';
      timeString += ' until';
      this.createText(timeString);
    } else {
      this.createText('0H 0M 0S until');
    }

    /*
    this.createCountdownText(days,'days',this.posOffsets.d);
    this.createCountdownText(hours,'hours',this.posOffsets.h);
    this.createCountdownText(mins,'minutes',this.posOffsets.m);
    this.createCountdownText(secs,'seconds',this.posOffsets.s);
    */

  }

  this.createCountdownText = function(time, timename, offset){

    let countDownGroup = new THREE.Group();

    let timeGeo = new THREE.TextGeometry( time, {
      font: this.font,
      size: this.size,
      height: this.height,
      curveSegments: this.curveSegments,
      bevelThickness: this.bevelThickness,
      bevelSize: this.bevelSize,
      bevelEnabled: this.bevelEnabled
    });
    timeGeo.computeBoundingBox();

    let timeMesh = new THREE.Mesh( timeGeo, this.materials );
    timeMesh.position.x -= timeGeo.boundingBox.max.x;

    let timeNameGeo = new THREE.TextGeometry( timename, {
      font: this.font,
      size: this.size*.5,
      height: this.height,
      curveSegments: this.curveSegments,
      bevelThickness: this.bevelThickness,
      bevelSize: this.bevelSize,
      bevelEnabled: this.bevelEnabled
    });
    timeNameGeo.computeBoundingBox();
    let timeNameMesh = new THREE.Mesh( timeNameGeo, this.materials );
    timeNameMesh.position.x += 30;
    timeNameMesh.position.y += timeNameGeo.boundingBox.max.y  * 1.10;

    countDownGroup.add( timeMesh );
    countDownGroup.add( timeNameMesh );

    const countDownGroupBox = new THREE.Box3();
    countDownGroupBox.setFromObject( countDownGroup );
    // countDownGroupBox.computeBoundingBox();

    //countDownGroup.computeBoundingBox();
    //  const centerThem = - 0.5 * ( countDownGroup.boundingBox.max.x - countDownGroup.boundingBox.min.x );
    const centerThem = 0; // countDownGroupBox.getSize().x * 0.5;

    countDownGroup.position.x = offset.x;
    countDownGroup.position.y = offset.y;
    countDownGroup.position.z = offset.z;
    countDownGroup.position.x += centerThem;
    countDownGroup.rotation.y = Math.PI * 2;

    /*
    const centerOffset = centerIt

    this.textMeshCountdown = new THREE.Mesh( this.timesGeo, this.materials );

    this.textMeshCountdown.position.x = centerOffset;
    this.textMeshCountdown.position.y = 0;
    this.textMeshCountdown.position.z = 0;

    this.textMeshCountdown.rotation.x = 0;
    this.textMeshCountdown.rotation.y = Math.PI * 2;
    */

    this.group.add( countDownGroup );

  }

  this.createText = function(text){

    // Times
    this.timesGeo = new THREE.TextGeometry( text, {
      font: this.font,
      size: this.size,
      height: this.height,
      curveSegments: this.curveSegments,
      bevelThickness: this.bevelThickness,
      bevelSize: this.bevelSize,
      bevelEnabled: this.bevelEnabled
    });
    this.timesGeo.computeBoundingBox();


    const centerOffset = - 0.49 * ( this.timesGeo.boundingBox.max.x - this.timesGeo.boundingBox.min.x );

    this.textMeshCountdown = new THREE.Mesh( this.timesGeo, this.materials );

    this.textMeshCountdown.position.x = centerOffset;
    this.textMeshCountdown.position.y = 0;
    this.textMeshCountdown.position.z = 0;

    this.textMeshCountdown.rotation.x = 0;
    this.textMeshCountdown.rotation.y = Math.PI * 2;

    this.group.add( this.textMeshCountdown );

  }

  this.countdown = function(until){

    var now = DateTime.now().setZone('Europe/Helsinki');

    var interval = Interval.fromDateTimes(now, until).toDuration(['days', 'hours', 'minutes', 'seconds']).toObject();

    this.left = interval;

    return interval;
/*
    let BigDay          = until;
    let msPerDay        = 24 * 60 * 60 * 1000;
    let timeLeft        = (BigDay.getTime() - today.getTime());
    let e_daysLeft      = timeLeft / msPerDay;
    let daysLeft        = Math.floor(e_daysLeft);
    let e_hrsLeft       = e_daysLeft*24;
    let hrsLeft         = Math.floor(e_hrsLeft);
    let e_minsLeft       = e_hrsLeft*60;
    let minsLeft        = Math.floor((e_hrsLeft - hrsLeft)*60);
    let secsLeft        = Math.floor((e_minsLeft - minsLeft)*60);
    return {days:daysLeft, hours:hrsLeft, mins:minsLeft, secs:secsLeft}
    */
    // document.getElementById('output').innerHTML = "<p>There are only<b> " + daysLeft + " days " + hrsLeft +" hours and " + minsLeft + " minutes left </b> Until December 25th 2025</p>";
  }

  this.countdown(this.until);

  this.init();

  /*
  //Call on intial point
  countdown();
  window.setInterval(function(){
    countdown();
  },60000) //Auto time after every 1 minute
  */


/*
  this.loadFont = function(err, font) {
    // create a geometry of packed bitmap glyphs,
    // word wrapped to 300px and right-aligned
    var geometry = createGeometry({
      width: 300,
      align: 'right',
      font: font
    })

    // change text and other options as desired
    // the options sepcified in constructor will
    // be used as defaults
    geometry.update(this.textContent)

    // the resulting layout has metrics and bounds
    console.log(geometry.layout.height)
    console.log(geometry.layout.descender)

    // the texture atlas containing our glyphs
    var textureLoader = new THREE.TextureLoader();
    textureLoader.load('fonts/Arial.png', function (texture) {
      // we can use a simple ThreeJS material
      var material = new THREE.MeshBasicMaterial({
        map: texture,
        transparent: true,
        color: 0xaaffff
      })

      // now do something with our mesh!
      var mesh = new THREE.Mesh(geometry, material)
    })
  } // end loadFont
*/

}

export { LaunchTimer };
